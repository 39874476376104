.home-page{
  display: flex;
  flex-direction: column;

  &__contact{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__projects{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__error{
    text-align: center;
    font-size: 40px;
  }
}
