.profile {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;

  img {
    width: 200px;
    aspect-ratio: 1;
    object-fit: cover;

    border: 4px solid #F7CB15;
    border-radius: 100px;
  }

  &__name {
    font-weight: bold;
    font-size: 30px;
  }
}
